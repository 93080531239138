
import { defineComponent, onMounted, ref, watch } from 'vue';
import useWallet from '@/composables/wallet';
import useCluster from '@/composables/cluster';
import { initGemFarm } from '@/common/gem-farm';
import { PublicKey } from '@solana/web3.js';
import { RarityConfig } from 'tests/gem-farm/gem-farm.client';

export default defineComponent({
  props: {
    farm: PublicKey,
  },
  setup(props, ctx) {
    const { wallet, getWallet } = useWallet();
    const { cluster, getConnection } = useCluster();
    const bs58 = require('bs58');

    let gf: any;
    watch([wallet, cluster], async () => {
      gf = await initGemFarm(getConnection(), getWallet()!);
    });

    //need an onmounted hook because this component isn't yet mounted when wallet/cluster are set
    onMounted(async () => {
      if (getWallet() && getConnection()) {
        gf = await initGemFarm(getConnection(), getWallet()!);
      }
    });

    // --------------------------------------- rarity
    const rarityConfigInput = ref<String>();

    const nftRarityConfig = async () => {
      //converts the inputted string into an array consiting of two possible values: "mint":"KEYHERE"  and "rarityPoints":NUMBERHERE
      let rarityConfig = rarityConfigInput.value!.replace(/\s/g, '');
      rarityConfig = rarityConfig.replaceAll("{", "");
      rarityConfig = rarityConfig.replaceAll("}", "")
      let rarityConfigStrings = rarityConfig.split(',');


      // stores all of the rarity configs to use in addRaritiesToBank
      var rarityConfigList: RarityConfig[] = [];
      
      //temp value
      let rarityConfigTemp: RarityConfig = {"mint": new PublicKey("EKywTpthg18rdeUroibiJbXgHbku8JzYedw8T4xehUmM"), "rarityPoints": 1}


      //loops through all mint and raritypoint values
      for (let rarity in rarityConfigStrings) {
        //splits the string into key pair values (e.g. "rarityPoints" and it's corresponding value)
        const split = rarityConfigStrings[rarity].split(":");
        // either rarityPoints or mint
        const key = split[0];
        // the values associated with the two keys above
        const val = split[1];

        if (key === "\"mint\"") {
          // doesn't like " at the start and end of string for base58 encoding
          var publickey: String = split[1].replaceAll("\"", "");

          // is new publicKey correct?
          rarityConfigTemp = {"mint": new PublicKey("EKywTpthg18rdeUroibiJbXgHbku8JzYedw8T4xehUmM"), "rarityPoints": 10}
          rarityConfigTemp.mint = new PublicKey(publickey);
        }

        //lazy implementation: we know the second value is always raritypoints so push to our main list
        if (key === "\"rarityPoints\"") {
          rarityConfigTemp.rarityPoints = parseInt(split[1]);
          console.log(rarityConfigTemp);
          rarityConfigList.push(rarityConfigTemp);
        }
      }


      console.log("rarityConfigList: " + rarityConfigList);
      await gf!.addRaritiesToBank(props!.farm!, getWallet()!.publicKey!, rarityConfigList);
    };

    return {
      rarityConfigInput,
      nftRarityConfig
    };
  },
});
