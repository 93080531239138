import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-417bb22d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overflow-hidden card-div items-center" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "nft-text" }
const _hoisted_4 = { class: "font-bold text-xl mb-2" }
const _hoisted_5 = { class: "button-holder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.nft.externalMetadata.image,
      alt: _ctx.nft.onchainMetadata.data.name,
      class: "nft-image"
    }, null, 8, _hoisted_2),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString("#" + _ctx.nft.onchainMetadata.data.name.split("#")[1]), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.vault)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args))),
              class: "bg-dark button-unstake"
            }, " Unstake "))
          : _createCommentVNode("", true),
        (!_ctx.vault)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args))),
              class: "bg-dark button-stake"
            }, " Stake "))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}