import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "justify-between items-center" }
const _hoisted_2 = { class: "mb-10 flex justify-center" }
const _hoisted_3 = { class: "items-stretch" }
const _hoisted_4 = { class: "items-stretch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFTGrid = _resolveComponent("NFTGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.bank && _ctx.vault)
        ? (_openBlock(), _createBlock(_component_NFTGrid, {
            key: 0,
            title: "Your Vault",
            class: "flex-1",
            nfts: _ctx.desiredVaultNFTs,
            vault: true,
            farm: _ctx.farm,
            onSelected: _ctx.handleVaultSelected
          }, null, 8, ["nfts", "farm", "onSelected"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_NFTGrid, {
        title: "Your Wallet",
        class: "flex-1",
        nfts: _ctx.desiredWalletNFTs,
        farm: _ctx.farm,
        onSelected: _ctx.handleWalletSelected
      }, null, 8, ["nfts", "farm", "onSelected"])
    ])
  ]))
}