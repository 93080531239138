import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "nft-grid-title" }
const _hoisted_2 = { class: "flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NFTCard = _resolveComponent("NFTCard")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft) => {
        return (_openBlock(), _createElementBlock("div", null, [
          ((_ctx.farm == '474jvzhTBurh1YUdyABK5rfYgCr8VD7o2U9GW7sbNPNP' && nft.onchainMetadata.updateAuthority == 'FNuh9itGe4eWTsci13a8ZKMzcqqNcHpJp3qYy7GjW8gz')
            || (_ctx.farm == '4QAAYozUaPZZ9xpx2sJGq8nA8R9yp5sjsn6diC8NiSaZ' && nft.onchainMetadata.updateAuthority == '9u79RMaBWbNFKrwiVX5JTXY4Qu4oofBBVRD5NB7Yhf1n'))
            ? (_openBlock(), _createBlock(_component_NFTCard, {
                key: nft,
                nft: nft,
                vault: _ctx.vault,
                onSelected: _ctx.handleSelected
              }, null, 8, ["nft", "vault", "onSelected"]))
            : _createCommentVNode("", true)
        ]))
      }), 256))
    ])
  ]))
}